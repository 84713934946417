<template>
    <main>
        <div class="card shadow-none" v-for="(item,index) in $v.formulario.$each.$iter" :key="item.id">
            <div  class="card-header">
                {{item.$model.nombre}}
            </div>    
            <div class="card-body">
           <div class="row mt-2">
            <div class="col-lg-3" v-for="(pregunta, pIndex) in item.$model.preguntas" :key="`pregunta_${pIndex}`">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck 
                            :antecedente="pregunta" 
                            :label="pregunta.nombre" 
                            :type="pregunta.tipo"
                            :validator="$v.formulario.$each[index].preguntas.$each[pIndex].respuesta"
                            v-model="pregunta.respuesta">
                        </buttonsCheck>
                        
                    </div>
                </div>
            </div>

           </div>
        </div>
       
        <div class="alert alert-danger text-center" v-if="$v.formulario.$error && $v.formulario.$dirty">Debe completar el formulario</div> 
    </main>
</template>
<script>
import formulariosService from "../../../services/formulariosService";
import Toast from '../../../components/common/utilities/toast';
import respuestasFormulariosService from '../../../services/respuestasFormulariosService';
import {isEmpty} from "lodash";
import {required} from "vuelidate/lib/validators";
import buttonsCheck from '../base/baseButtonsCkeck/indexButtonsNoOptions';
export default {
    props: ['idHistoria','finalizada','codigo','trimestre'],
    components:{buttonsCheck},
    data() {
        return {
           formulario:{},
           id_formulario : '',         
        }
    },
    validations (){
        return {
            formulario : {
                $each : {
                    preguntas:{
                        $each : {
                            respuesta: {required}
                        }
                    }
                }
            },
        }
    },
    methods: {
        async cargarFormulario(){

            try {

                this.LoaderSpinnerShow();

                const response = await formulariosService.showByCode(this.codigo);
                this.id_formulario = response.data.id;

                const respuestas_formulario=await respuestasFormulariosService.showByRespuestableId(
                    {
                        id:this.idHistoria,
                        id_formulario:this.id_formulario,
                        trimestre:this.trimestre!==''?this.trimestre:'',
                    }
                );
                

                this.formulario=response.data.secciones.map(x=>{
                    
                    return {
                        id:x.id,
                        nombre:x.nombre,
                        preguntas:x.preguntas.map(y=>{
                            let respuesta='';
                            if(!isEmpty(respuestas_formulario.data)){
                                respuesta=respuestas_formulario.data.items.find(r=>r.id_pregunta===y.id)?.valor || ''
                            }
                            const tipoMap = {
                                'TEXTO': 'TEXT',
                                'OPCIONES': 'OPC',
                                'FECHA': 'DATE',
                                'NUMERO': 'NUM'
                            };

                            return {
                                id:y.id,
                                id_seccion:y.id_seccion,
                                nombre:y.nombre,
                                opciones:y.opciones,
                                tipo:tipoMap[y.tipo] || '',
                                respuesta:respuesta,
                            }
                        })
                    }
                });

                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
            
        },
        async save() {
            try{

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let respuestas=[];
                
                this.formulario.forEach(x=>{

                    x.preguntas.reduce((t,c) => {
                        let opcion = c.opciones.find(opc=>opc.opcion===c.respuesta);
                        return opcion!==undefined?opcion.id:0;
                    },0); 

                    let respuestas_secciones=x.preguntas.map(y=>{
                        let opcion=y.opciones.find(opc=>opc.texto===y.respuesta);
                        return {
                            id_pregunta:y.id,
                            respuesta:y.respuesta,
                            id_opcion:opcion!==undefined?opcion.id:'',
                        }
                    })

                    respuestas.push(...respuestas_secciones);
                });

                let respuestas_historia={
                    id_historia: this.idHistoria,
                    id_formulario:this.id_formulario,
                    respuestas:respuestas,
                    trimestre:this.trimestre
                }

                console.log(respuestas_historia);
        
                this.LoaderSpinnerShow();
                
                await respuestasFormulariosService.store(respuestas_historia);
            
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }  
        }, 
    },
    async created(){
       await this.cargarFormulario();
    },
    watch: {
        trimestre: async function (value){ 
            if(value){
                this.cargarFormulario();                
            }
        }
    },
    
}
</script>